import React from 'react';

import '../App.css';
import "../styles/home.css"

import Bio from "../content/Bio";
import Lists from '../Lists/Lists';
import links from '../content/links.json';
export default class Home extends React.Component {
  render() {
    return (
      <div className="App content">
        <div className='left headshot-container'>
          <img src="./images/profile.jpg" className="headshot" alt="Headshot of Jack Kenney" />
        </div>
        <div className='right'>
          <div className="header">
            <h1 className='title'>Jack Kenney</h1>
            <span className="tagline">Developer. Artist. Scientist.</span>
            <Lists lists={links} />
          </div>
          <Bio />
        </div>
      </div>
    )
  }
}
